//@ts-check
import React, { Component } from 'react';
//import authService from './api-authorization/AuthorizeService';
import { Link } from "react-router-dom";
//import { Option } from "./option";
import { useState } from 'react';

var problem_default = {
    Start: false, Solved: false,
    Result: ["my result"], Replacements: ["", ""],
    currentAnswer: "", currentValue: "", currentComment: "default", questionHead: "", questionCode: "", 
    questions: [["carrot_from_front", ""], ["fox_from_front", ""]],
    answersStack: "",
    fileName: ""
}

const Option = (props) => {

    const [hover, setHover] = useState(false);

    const handleMouseEnter = () => {
        setHover(true);
    };

    const handleMouseLeave = () => {
        setHover(false);
    };

    return (<h4
        style={{
            backgroundColor: hover ? '#3399ff' : 'white',
            cursor: 'pointer',
        }}
        key={props.pr}
        id={props.id}
        onClick={props.handleCheck}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
    > - {props.txt}
    </h4>);
}

const TextArea = (props) => {
    return <div className="row">
        <div className="col-sm-10">
            <label htmlFor='current_comment' className="form-label">Дополнительные комментарии (опционально)</label>
            <textarea style={{ whiteSpace: 'pre-wrap' }} className="form-control auto-resize"
                name='current_comment' value={props.value}
                onChange={props.handleChange}
            />
        </div>
    </div>
}


const Input = (props) => {
    return <div className="row">
        <div className="col-sm-10">
            <label htmlFor='current_comment' className="form-label">Вводить сюда</label>
            <input style={{ whiteSpace: 'pre-wrap' }} className="form-control auto-resize"
                id={props.id} name='current_value' value={props.value}
                onChange={props.handleChange}
            />
        </div>
    </div>
}


const MyPara = (props) => {
    return (<h4 key={props.pr} id={props.id} onClick={props.handleCheck}> - { props.txt } </h4> );
}


const StartFlow = (props) => {
    return (<button className="btn btn-primary" onClick={props.handleStartFlow}>Restart flow</button>);
}

const SubmitButton = (props) => {
    return (<button className="btn btn-primary" value={props.value} onClick={props.handleSubmit}>Submit</button>);
}


const StartSMthing = (props) => {
    return (<button className="btn btn-primary">another button</button>);
}


const Download = (props) => { 
    return ( <div className="row">
        <a
        href={props.file}
        download="word_document"
        target="_blank"
        rel="noreferrer">
        <button>Download file</button>
        </a>
    </div> )
}

const Paragraph = (props) => { return <p>para </p> }


export class Solversmo extends Component {
    static displayName = Solversmo.name;

  constructor(props) {
      super(props);

      this.state = { problem: problem_default, loading: true };

      this.handleSelect = this.handleSelect.bind(this);
      this.handleChangeComment = this.handleChangeComment.bind(this);
      this.handleStartFlow = this.handleStartFlow.bind(this);
      this.handleChangeInput = this.handleChangeInput.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
  }


  componentDidMount() {
    this.fetchSolver();
    }


    async handleSubmit() {

        let pr = this.state.problem
        //pr.currentAnswer = currentAnswer
        this.setState({ loading: true });
        //const token = await authService.getAccessToken();
        //await fetch
        const response = await fetch('solversmo', {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(pr) 
        });

        if (response.ok)
        {
            let data = await response.json();
            data.answersStack = this.state.problem.answersStack
            this.setState({ problem: data, loading: false });
                console.log('handleSubmit end state: ' + this.state);
        }
        else { console.log("not Ok 1") }

    }








    async handleSelect(event) {
        this.setState({ loading: true });
        // changing embeded states
        // https://stackoverflow.com/questions/43040721/how-to-update-nested-state-properties-in-react
        event.preventDefault();
        console.log('id - ' + event.target.id);
        //var new_standard = { ...this.state.standard }
        //new_standard[event.target.name]['value'] = event.target.value;
        //this.setState({ standard: new_standard });
        problem_default.Start = false;
        problem_default.answersStack = problem_default.answersStack + ' ' + event.target.id

        var problem_updated = { ...this.state.problem }
        problem_updated.answersStack = problem_updated.answersStack + ' ' + event.target.id;
        this.setState({ problem: problem_updated });
        //this.state.problem.answersStack
        //kn.Solved = false;
        problem_default.currentAnswer = event.target.id;
        this.fetchSolver();
    }

    async handleStartFlow(event) {
        // changing embeded states
        // https://stackoverflow.com/questions/43040721/how-to-update-nested-state-properties-in-react
        event.preventDefault();
        this.setState({ loading: true });
        //var new_standard = { ...this.state.standard }
        //new_standard[event.target.name]['value'] = event.target.value;
        //this.setState({ standard: new_standard });
        problem_default.answersStack = "";
        problem_default.currentAnswer = "";
        problem_default.Start = true;
        problem_default.Solved = false;
        var problem_updated = { ...this.state.problem }
        problem_updated.answersStack = "";
        this.setState({ problem: problem_updated });
        //kn.Selection = event.target.id;        
        this.fetchSolver();
    }    

    handleChangeComment(event) {
        // changing embeded states
        // https://stackoverflow.com/questions/43040721/how-to-update-nested-state-properties-in-react
        //new_standard[event.target.name]['value'] = event.target.value;
        console.log(this.state.problem)
        var problem_updated = { ...this.state.problem }
        problem_updated.currentComment = event.target.value;
        this.setState({ problem: problem_updated });
        //this.setState({ problem.CurrentComment : event.target.value });
    }

    handleChangeInput(event) {
        // changing embeded states
        this.setState({ loading: true });
        // https://stackoverflow.com/questions/43040721/how-to-update-nested-state-properties-in-react
        //new_standard[event.target.name]['value'] = event.target.value;
        console.log('handleChangeInput - event: ' + event.id)
        console.log('handleChangeInput - problem: ' + this.state.problem)
        var problem_updated = { ...this.state.problem }
        const x = this.state.problem.questions
        const y = x[0]
        const z = y[0]
        problem_updated.currentAnswer = z;
        problem_updated.currentValue = event.target.value;
        this.setState({ problem: problem_updated });
        //this.setState({ problem.CurrentComment : event.target.value });
    }



    render() {

        let loading = this.state.loading
        let problem = this.state.problem
        let questions = this.state.problem.questions
        let s_ = problem.Solved
        let S_ = problem.solved
        let fileName = this.state.problem.fileName
        //let fileName = './' + "Word_test_file.docx"


        // if only one answer option => asking to input value
        let current_value = loading ? <p></p> : <Input
            id={this.state.problem.questions[0][0]}
            value={this.state.problem.currentValue}
            handleChange={this.handleChangeInput} />

        let downlaod = S_ ? <Download file={fileName} /> : <br />


        let result_txt = this.state.problem.result
        // aks vs ask
        let options_aks = questions.length == 1 ? current_value : questions.map((pr, i) => <Option key={i} id={pr[0]} txt={pr[1]} handleCheck={this.handleSelect} />);
        let question_head = loading ? <h3></h3> : <h3>{problem.questionHead}</h3>
        let contents = loading ? <p><em>Loading...</em></p> : <div> {options_aks} </div>
        //let replacements = this.state.loading ? <p><em>Loading...</em></p> : this.state.problem.replacements.map((r, i) => <div key={i} dangerouslySetInnerHTML={{ __html: r }} />)
        let replacements = loading ? <p></p> : problem.replacements.filter(x => x !== "").map((r, i) => <div key={i} dangerouslySetInnerHTML={{ __html: r }} />)
        //let q_a = loading && this.state.problem.q_A.length > 0 ? this.state.problem.q_a.map((pr, i) => <p key={i}> {pr[0]} {pr[1]} </p>) : <p></p>
        //FetchData.renderForecastsTable(this.state.problem.questions);
        //<h1> {this.state.problem.result} </h1>

        //let solved = this.state.problem.start

        let current_comment = loading ? <p></p> : <TextArea
            value={this.state.problem.currentComment}
            handleChange={this.handleChangeComment} />


        let currentAnswer = ''
        if (this.state.problem.questions.length == 1) {
            let problem_updated = { ...this.state.problem }
            const x = this.state.problem.questions
            const y = x[0]
            const z = y[0]
            problem_updated.currentAnswer = z;
            currentAnswer = z
            // causes infinite state updates
            //this.setState({ problem: problem_updated });
            //this.fetchSolver()
            //console.log('set currentAnswer ' + this.state.problem)
        }



        return (
            <div>
                <h1 id="tabelLabel"></h1>
                <br /><br />

                { !loading && <StartFlow handleStartFlow={this.handleStartFlow} /> }
                <br /><br />

                {question_head}
                <br />

                {replacements}
                <br />

                {!loading && problem.solved ?
                    result_txt.map((pr, i) => <div className="shadow-lg p-3 mb-5 bg-white rounded" dangerouslySetInnerHTML={{ __html: pr }} />) : contents}
                <br /><br />



                {fileName != "" ? downlaod : ""}
                <br />

                {current_comment}
                <br />

                {questions.length == 1 ? <SubmitButton value={currentAnswer} handleSubmit={this.handleSubmit} /> : <p></p>}
                <br />




            </div>
        );
    }



    async fetchSolver() {

        //const token = await authService.getAccessToken();

        const response = await fetch('solversmo', {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(problem_default) // WHY problem_default ??? NB
        });

        if (response.ok) {
            let data = await response.json();

            data.answersStack = this.state.problem.answersStack
            //var problem_updated = { ...this.state.problem }
            //problem_updated.currentValue = event.target.value;
            //this.setState({ problem: problem_updated });
            problem_default.questionCode = data.questionCode

            this.setState({ problem: data, loading: false });
            console.log(this.state);
        }
        else { console.log("not Ok 2") }
    }

}
