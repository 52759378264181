import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { FileUpload } from "./components/FileUpload";
import { SolverFront } from "./components/SolverFront";
import { Solversmo } from "./components/Solversmo";
import { Home } from "./components/Home";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/counter',
    element: <Counter />
  },
  {
    path: '/fetch-data',
    element: <FetchData />
    },
    {
        path: '/file-upload',
        element: <FileUpload />
    },
    {
        path: '/solver-front',
        element: <SolverFront />
    },
    {
        path: '/solver-smo',
        element: <Solversmo />
    },
];

export default AppRoutes;
