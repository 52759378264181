import React, { Component } from 'react';
import { Link } from "react-router-dom";

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
        <div>

            
            <h2>Добро пожаловать на сайт "Lawcaster"!</h2>

            <p>Мы рады представить вам уникальное веб-приложение, созданное специально для оказания правовой помощи в актуальных для российского общества сферах. Наша миссия - обеспечить качественную и эффективную поддержку тем, кому она действительно необходима.</p>

            <p>Что мы предлагаем:</p>
            <p>1. Военнослужащим и их семьям: наше веб-приложение предоставляет удобный инструмент "Правовая помощь участникам СВО" для автоматизированной подготовки заявлений, которые помогут защитить ваши права в сложных условиях специальных военных операций на Украине. Мы поможем направить эти заявления в соответствующие органы государственной власти, чтобы обеспечить вашу защиту и восстановление прав военнослужащего и ваших близких.</p>
            <p>2. Потребителям: с помощью инструмента "Защита потребителя" нашего веб-приложения вы сможете автоматизированно подготовить претензии и обращения в случае, если вы столкнулись с приобретением товаров, работ или услуг ненадлежащего качества или, что еще более важно, небезопасных для вашей жизни, здоровья или имущества. Мы поможем вам обратиться к производителям, изготовителям, продавцам и подрядчикам и добиться справедливости.</p>
            <p>3. Предпринимателям и организациям: мы понимаем сложности, с которыми вы сталкиваетесь при проведении трансграничных хозяйственных операций. Инструмент нашего веб-приложения "Быстрая налоговая проверка" ("TaxQuickCheck") поможет вам автоматизированно определить налоговые последствия таких операций, что позволит существенно упростить и обезопасить ваш бизнес.(в тестовом режиме)</p>

            <p>Для формирования проекта заявления пройдите по ссылке  -&gt;: {<Link className="text-dark" to="/solver-front"><b>Solver</b></Link>}</p>

            <p>Более подробно о компонентах проекта:</p>
            <p>1. "Правовая помощь участникам СВО" - специальный компонент нашего проекта, направленный на поддержку и оказание помощи военнослужащим Вооруженных Сил Российской Федерации, которые исполняют обязанности по защите Отечества в период проведения специальной военной операции на Украине, а также их семьям. Наше веб-приложение предоставляет возможность составить и разработать проект заявления военнослужащего в компетентные государственные органы с сообщением о нарушении прав и несоблюдении гарантий военнослужащего. Мы помогаем обеспечить автоматизированную подготовку обращений и жалоб в защиту прав военнослужащих, среди них права на получение льгот по уплате налогов, возможности использования кредитных каникул, права на получение денежны выплат по ранению, единовременных выплат и ежемесячного денежного содержания военнослужащего.</p>
            <p>2. "Защита потребителя" - второй компонент нашего проекта, созданный для защиты прав потребителей в случае нарушения качества товаров, работ или услуг, приобретенных у продавцов, изготовителей, исполнителей или подрядчиков. С помощью нашего веб-приложения вы сможете самостоятельно и быстро сформировать претензию и обратиться в компетентные государственные органы. Мы стремимся защитить ваши права и обеспечить справедливость в случае возникновения споров с продавцами или поставщиками. Отмечаем, что при причинении товаром вреда вашему здоровью или здоровью ваших близких, а также при потенциально существенном ущербе для личного или семейного бюджета, рекомендуем обратиться к адвокату или юристу.</p>
            <p>3. "Быстрая налоговая проверка" - третий компонент проекта, предназначенный для помощи предпринимателям и организациям в определении налоговых последствий их трансграничных хозяйственных операций в сегменте B2B. В нашей базе данных содержатся основные налоги: налоги постоянных представительств, налог на добавленную стоимость (НДС), налог удерживаемый у источника, налог на доходы физических лиц (НДФЛ). При помощи логического программирования на основе правил, наше веб-приложение предоставляет точные ответы на вопросы налогообложения, что сходно с анализом правовым норм человеком.</p>


            <p><b>Авторы проекта:</b></p>

            <div class="row">
                <div class="col-sm-6">

                    <p><b>Вячеслав Голенев</b></p>

                    <p>Опыт работы:</p>
                    <ul>
                        <li>Адвокат. Адвокатская палата города Москвы, № 77/12991 в реестре адвокатов города Москвы. Успешно представляет интересы доверителей в судах. Провел более 100 судебных процессов, в т.ч. в Верховном Суде РФ. Опыт работы 9 лет</li>
                    </ul>
                    <p>Контакты: <a target="_blank" href="https://vk.com/vgolenev"><b>VK</b></a> </p>

                    <img src={"./vgolenev.jpg"} width="256" height="296" />
                    <br />
                    <br />


                </div>
                <div class="col-sm-6">
                    <br />
                    <p><b>Владимир Зайцев</b></p>

                    <p>Опыт работы:</p>
                    <ul>
                        <li>8 лет в одной из компаний Big4</li>
                        <li>более 12 лет работы региональным налоговым директором крупной международной группы</li>
                    </ul>
                    <p>Контакты: <a target="_blank" href="https://vk.com/vladimir.s.zaytsev"><b>VK</b></a> </p>

                    <img src={"./vzaytsev.jpg"} width="256" height="296" />
                    <br />
                    <br />
                </div>

            </div>


      </div>
    );
  }
}
