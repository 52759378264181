import React, { Component } from 'react';
//import authService from './api-authorization/AuthorizeService';

export class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fileInput = React.createRef();
        this.state = { result: '', loading: false };
        //this.setState({ result: "" }) // this can be only inside class of function

    }
    handleSubmit(event) {
        event.preventDefault();
        alert(
            `Selected file - ${this.fileInput.current.files[0].name}`
        );
        this.uploadFile();

    }



    async uploadFile() {

        const formData = new FormData()
        formData.append('s', this.fileInput.current.files[0])

        //const token = await authService.getAccessToken();

        this.setState({ result: "", loading: true });

        const response = await fetch('fileupload/upload', {
            method: 'POST',
            body: formData
        });

        const data = await response.text();
        //console.log(data);
        this.setState({ result: data, loading: false });
    }



    render() {
        return (

            <div>
            <form onSubmit={this.handleSubmit}>
                <label>
                    Upload file: 
                    <input className="form-control" type="file" ref={this.fileInput} />
                </label>
                <br />
                    { this.state.loading ? <p> Awaiting upload to complete </p>  : <button className="btn btn-primary" type="submit">Submit</button> } 
                </form>
                <h3>{this.state.result ? this.state.result : ""  }</h3>
             </div>
        );
    }
}

/*
ReactDOM.render(
    <FileInput />,
    document.getElementById('root')
);
*/